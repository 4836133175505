<template>
    <div class="page_wrapper reg_form">
        <div class="page_labels mb-7">
            <h1 class="mb-2">
                {{ $t('auth.code_verification') }}
            </h1>
        </div>
        <a-form-model
            ref="authForm"
            :model="form"
            layout="vertical"
            autocomplete="off"
            :rules="rules">
            <a-form-model-item
                ref="confirm_code"
                class="w-full mb-0 pb-0"
                prop="confirm_code">
                <div class="flex items-center">
                    <a-input
                        v-model="form.confirm_code"
                        autocomplete="off"
                        :placeholder="$t('auth.sms_code_placeholder')"
                        class="w-full"
                        size="large"
                        @pressEnter="formSubmit()" />
                    <a-button
                        type="primary"
                        :loading="loading"
                        class="form_buttom ml-3 px-10"
                        size="large"
                        @click="formSubmit()">
                        {{ $t('auth.continue') }}
                    </a-button>
                </div>
            </a-form-model-item>
        </a-form-model>
        <div class="flex items-center justify-between mt-2">
            <span @click="setForgotStep(1)" class="cursor-pointer link_btn flex items-center">
                <i class="fi fi-rr-angle-small-left mr-1"></i>
                {{ $t('auth.back') }}
            </span>
            <div class="flex items-center">
                <template v-if="countdownSubmit">
                    <a-button size="small" type="link" class="py-0" style="height: 20px;" :loading="codeLoader" @click="codeSubmit()">
                        {{ $t('auth.resend_code') }}
                    </a-button>
                </template>
                <template v-else>
                    <span class="mr-1">{{ $t('auth.resend_code_after') }}</span>
                    <a-statistic-countdown
                        class="phone_countdown"
                        format="mm:ss"
                        :value="deadline"
                        @finish="countdownFinish"/>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        setForgotStep: {
            type: Function,
            default: () => {}
        },
        globalForm: {
            type: Object,
            default: () => null
        },
        setGlobalForm: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            deadline: this.$moment().add(2, 'minutes'),
            countdownSubmit: false,
            loading: false,
            codeLoader: false,
            form: {
                confirm_code: ''
            },
            rules: {
                confirm_code: [
                    { required: true, type: 'string', message: this.$t('field_required'), trigger: 'change' },
                    { max: 255, message: this.$t('required_sym', { sym: 255 }), trigger: 'change' },
                ]
            }
        }
    },
    methods: {
        async codeSubmit() {
            try {
                this.codeLoader = true
                const formData = JSON.parse(JSON.stringify(this.globalForm))
                const { data } = await this.$http.post('/users/forgot_password/', formData)
                if(data?.message === 'ok') {
                    this.countdownSubmit = false
                    this.deadline = this.$moment().add(2, 'minutes')
                }
            } catch(error) {
                if(error.message) {
                    this.$message.error(error.message, 4)
                }
                console.log(error)
            } finally {
                this.codeLoader = false
            }
        },
        countdownFinish() {
            this.countdownSubmit = true
        },
        formSubmit() {
            this.$refs.authForm.validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                        let formData = {
                            ...this.globalForm,
                            ...this.form
                        }
                        const { data } = await this.$http.post('/users/reset/confirm/', formData)
                        if(data?.uuid) {
                            delete formData.confirm_code
                            this.setGlobalForm({
                                ...formData,
                                uuid: data.uuid
                            })
                            this.setForgotStep(3)
                        }
                    } catch(error) {
                        if(error.message) {
                            this.$message.error(error.message, 4)
                        }
                        this.form.confirm_code = ''
                        console.log(error)
                    } finally {
                        this.loading = false
                    }
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.phone_countdown{
    &::v-deep{
        .ant-statistic-content{
            font-size: 14px;
        }
    }
}
</style>